<template>
<v-card elevation="0">
    <v-card-text>
    <v-data-table :headers="headers"
        :items="includes"
        sort-by="name"
        class="elevation-1"
    >
        <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Includes</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog scrollable v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                        New Item
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="editedItem._id" label="id" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="editedItem.desc" label="Desc"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="editedItem.longdesc" label="longdesc"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-file-input accept="image/png" label="Icon" @change="onFileChanged"></v-file-input>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                            Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <!-- <v-icon small @click="deleteItem(item)">
                mdi-delete
            </v-icon> -->
        </template>
        <template v-slot:[`item.icon`]="{ item }">
          <v-img :src="$store.state.config.backend + 'icons/' + item.icon_filename" width="30" height="30"></v-img>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
</v-card>
</template>

<script>
  // const short = require('short-uuid');
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Desc', value: 'desc' },
        { text: 'Long Desc', value: 'longdesc' },
        { text: 'Icon', value: 'icon' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        _id: '',
        desc: '',
        longdesc: '',
        icon: {},
      },
      defaultItem: {
        _id: '',
        desc: '',
        longdesc: '',
        icon: {},
      },
      includes: [],
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    //   vendors() {
    //       return this.$store.state.vendor.objs
    //   }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.$store.dispatch('include/getObjs').then(response => {
          console.log(response)
            this.includes = response
        })
      },

      editItem (item) {
        this.editedIndex = this.includes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.includes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.includes.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.includes[this.editedIndex], this.editedItem)
        } else {
          delete this.editedItem._id
          this.includes.push(this.editedItem)
        }
        this.editedItem.icon_filename = this.editedItem.icon.name
        // delete this.editedItem.icon
        console.log(this.editedItem)
        
        this.$store.dispatch('include/postObj', this.editedItem).then(response => {
            console.log(response)
            this.close()
            this.initialize()
        }, err => {
            console.log(err)
            this.close()
        })
      },
      getBase64(file) {
          return new Promise((resolve, reject) => {
              var reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = function () {
                  resolve(reader.result);
              };
              reader.onerror = function (error) {
                  reject('Error: ', error);
              };
          })
      },

      onFileChanged(image) {
        if (!image) return
        this.getBase64(image).then((response)=>{
            var temp = {}
            temp.base64 = response
            temp.name = image.name
            temp.size = image.size
            temp.type = image.type
            temp.lastModified = image.lastModified
            this.editedItem.icon = temp
            console.log(this.editedItem)
        })
      },

    },
  }
</script>